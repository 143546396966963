import React from "react";
import classnames from 'classnames';
import {Container} from 'semantic-ui-react';
import "./GameButton.scss";

export default function GameButton({ title,  pressed, onClick }) {
  return (
    <Container textAlign="center">

    <button className={classnames({"gameButton":true,pressed:pressed})} onClick={onClick}>
      <span>{title}</span>
    </button>
    </Container>
  );
}
