import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import FAILVIDEO from "../assets/videos/FINKO.mp4";
import OKVIDEO from "../assets/videos/FINOK.mp4";
import Logo from "../assets/log PBX H.png";
import { useWindowSize } from "react-use";
import { saveData } from "./saveGoogle";
import Swal from "sweetalert2";

export default function KoBomb({
  showModal = true,
  okBomb,
  koBomb,
  setOkBomb,
  setKoBomb,
  setShowCodeInput,
}) {
  //TODO FIX BOOL
  const playerRef = useRef();
  const { width, height } = useWindowSize();
  const [answered, setAnswered] = useState(null);
  const [showMessageEnd, setShowMessageEnd] = useState(false);
  const currentTeam = window.localStorage.getItem("currentTeam");

  useEffect(() => {
    setShowMessageEnd(window.localStorage.getItem("showMessageEnd"));
  }, []);
  useEffect(() => {
    setAnswered(window.localStorage.getItem("answered"));
    if (showModal && !answered) {
      Swal.fire({
        title: "Se terminó el tiempo, ¿has desactivado la bomba?",
        customClass:"big",

        showCancelButton: true,
        confirmButtonText: `Si`,
        cancelButtonText: `No`,
      }).then(
        (result) => {
          const { isConfirmed, isDismissed } = result;
          if (isDismissed) {
            setKoBomb(true);
            setOkBomb(false);
            window.localStorage.setItem("forcedEnd", true);

            setAnswered(true);
            window.localStorage.setItem("answered", true);
          } else if (isConfirmed) {
            setShowCodeInput(true);

            window.localStorage.setItem("setShowCodeInput", true);

            setAnswered(true);
            window.localStorage.setItem("answered", true);
          }
        },
        () => {
          setOkBomb(false);

          setKoBomb(false);
          return false;
        }
      );
      saveData(true);
    }
    return () => {
      return false;
    };
  }, []);

  const handleProgress = (state) => {
    const { playedSeconds, loadedSeconds } = state;
    if (playedSeconds >= loadedSeconds) {
      setShowMessageEnd(true);
      window.localStorage.setItem("showMessageEnd", true);
    }
  };

  if (!answered) {
    return null;
  } else {
    return showMessageEnd ? (
      <div className="box-info-msg-end">
        <h2>Gracias equipo {currentTeam} por jugar con Pensabox</h2>
        <img src={Logo} />
      </div>
    ) : (
      <div className="video-screen">
        <div className="video">
          <ReactPlayer
            ref={playerRef}
            playing={true}
            width={width}
            height={height}
            url={okBomb ? OKVIDEO : koBomb ? FAILVIDEO : null}
            controls={false}
            onProgress={handleProgress}
          />
        </div>
      </div>
    );
  }
}
