import React from "react";
import "./Challenge.scss";
import Swal from "sweetalert2";
import imageClues from "./imagesClues";
import "sweetalert2/src/sweetalert2.scss";
import ClueButton from "../ClueButton/ClueButton";
import ReactPlayer from "react-player";
import videoCB from "../../assets/videoCB.mp4";
import imageClue from "../../assets/camino SOLUCION.png";
import withReactContent from "sweetalert2-react-content";

function saveWatchedClue(currentSectionIndex, challengeId, clueId, type) {
  console.log(type);
  const watchedClues =
    JSON.parse(window.localStorage.getItem("watchedClues")) || {};
  const numberClues =
    parseInt(window.localStorage.getItem("numberClues"), 10) || 0;
  const numberSols =
    parseInt(window.localStorage.getItem("numberSols"), 10) || 0;

  watchedClues[`${currentSectionIndex}${challengeId}${clueId}`] = true;
  window.localStorage.setItem("watchedClues", JSON.stringify(watchedClues));
  if (type === "sol") {
    window.localStorage.setItem("numberSols", numberSols + 1);
  } else {
    window.localStorage.setItem("numberClues", numberClues + 1);
  }
}
function checkWatchedClue(currentSectionIndex, challengeId, clueId) {
  const watchedClues =
    JSON.parse(window.localStorage.getItem("watchedClues")) || {};
  return watchedClues[`${currentSectionIndex}${challengeId}${clueId}`];
}

export default function Challenge({ challenge, currentSectionIndex }) {
  const Modal = withReactContent(Swal);

  const { challengeId } = challenge;

  return (
    <div className="challenge">
      <img src={imageClues[challenge.img]} alt={challenge.img} height="196px" />
      <div>
        {challenge?.clues &&
          challenge?.clues.map((clue, i) => {
            const { clueId, type = "clue", video, image } = clue;
            const alreadyWatched = checkWatchedClue(
              currentSectionIndex,
              challengeId,
              clueId
            );
            return (
              <ClueButton
                key={i}
                onClick={() => {
                  const launchModal = () => {
                    video || image
                      ? Modal.fire({
                          title: clue.title,
                          text: clue.description,
                          width: video ? "900px" : "413px",
                          html:
                            (video && (
                              <>
                                <ReactPlayer
                                  playing={true}
                                  url={videoCB}
                                  controls={false}
                                />
                              </>
                            )) ||
                            (image && (
                              <>
                                <h3>{clue.description}</h3>
                                <img src={imageClue} />
                              </>
                            )),
                        })
                      : Modal.fire({
                          title: clue.title,
                          text: clue.description,
                        });
                  };
                  if (alreadyWatched) {
                    launchModal();
                    return;
                  }
                  Swal.fire({
                    title: "¿Quieres esta pista?",

                    showCancelButton: true,
                    confirmButtonText: `Si`,
                    cancelButtonText: `No`,
                  }).then((result) => {
                    const { isConfirmed, isDismissed } = result;
                    /* Read more about isConfirmed, isDenied below */
                    if (isDismissed) {
                      return;
                    } else if (isConfirmed) {
                      saveWatchedClue(
                        currentSectionIndex,
                        challengeId,
                        clueId,
                        type
                      );
                      launchModal();
                    }
                  });
                }}
                title={clue.title}
                text={clue.text}
                image={clue.image}
                pressed={alreadyWatched}
              />
            );
          })}
      </div>
    </div>
  );
}
