import React, { useState } from "react";

export default function GameInit({ newTeamName }) {
  const [tempTeamName, setTempTeamName] = useState();
  const [focused, setFocused] = useState(false);
  const focusInput = () => {
    setFocused(true);
  };
  const blurInput = () => {
    setFocused(false);
  };
  return (
    <div className="gameinit">
      {!focused && (
        <>
          <span>Bienvenidos a LA BOMBA de pensabox.</span>
          <span>Antes de empezar necesitaréis un nombre de equipo:</span>
        </>
      )}
      <input
        onFocus={focusInput}
        onBlur={blurInput}
        placeholder="Nombre equipo"
        className="team-name__input"
        onChange={(a) => {
          setTempTeamName(a.target.value);
        }}
      />
      <button
        className="norm-button"
        onClick={() => {
          if (tempTeamName) {
            newTeamName(tempTeamName);
          }
        }}
      >
        Empezar el juego
      </button>
    </div>
  );
}
