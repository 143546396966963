import React, { useState, useEffect, useDebugValue } from "react";
import ReactPlayer from "react-player";
import INTROVIDEO from "../assets/videos/FINOK.mp4";
import Logo from "../assets/log PBX H.png";

import { saveData } from "./saveGoogle";
import { useWindowSize } from "react-use";
import useDigitInput from "react-digit-input";
import HeadShake from "react-reveal/HeadShake";

export default function OkBomb({
  setShowCodeInput,
  showCodeInput,
  setOkBomb,
  videoWatchedStartGame,
}) {
  const { width, height } = useWindowSize();
  const [showPenalty, setShowPenalty] = useState(false);
  const [value, onChange] = useState("");
  const [challengeMode, setChallengeMode] = useState();
  const [nSols, setNSols] = useState(0);
  const [nClues, setNClues] = useState(0);
  const [showMessageEnd, setShowMessageEnd] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const showVideoCorrect = value === "083" && accepted;
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 3,
    value,
    onChange,
  });
  useEffect(() => {
    setShowMessageEnd(window.localStorage.getItem("showMessageEnd"));
    setNClues(parseInt(window.localStorage.getItem("numberClues") || 0));
    setNSols(parseInt(window.localStorage.getItem("numberSols") || 0));
    if (window.localStorage.getItem("modeChallenge")) {
      setChallengeMode(
        window.localStorage.getItem("modeChallenge") === "true" ? true : false
      );
    }
  }, []);

  const currentTeam = window.localStorage.getItem("currentTeam");

  useEffect(() => {
    if (value !== "083" && accepted) {
      onChange("");
      setTimeout(() => {
        setAccepted(false);
      }, 1000);
    } else if (value === "083" && accepted) {
      saveData();
    }
  }, [accepted, value]);
  const handleProgress = (state) => {
    const { playedSeconds, loadedSeconds } = state;
    if (playedSeconds >= loadedSeconds) {
      setShowMessageEnd(true);
      window.localStorage.setItem("showMessageEnd", true);
    }
  };
  return (
    <>
      {showMessageEnd ? (
        <div className="box-info-msg-end">
          {showPenalty ? (
            <>
              <h3>
                Vuestra penalización total es de {nClues * 1 + nSols * 2}{" "}
                minutos y 0 segundos.
                <br /> Este tiempo tenéis que restarlo al que aparece en la
                pantalla de vuestro detonador.{" "}
                {challengeMode
                  ? "El equipo que más tiempo obtenga será el ganador."
                  : " y así obtendréis vuestro tiempo de competición."}
              </h3>
            </>
          ) : (
            <>
              <h2>Gracias equipo {currentTeam} por jugar con Pensabox</h2>
              <button
                className="clueButton"
                onClick={() => setShowPenalty(true)}
              >
                VER TIEMPO DE PENALIZACIÓN
              </button>
              <img src={Logo} />
            </>
          )}
        </div>
      ) : showVideoCorrect ? (
        <div className="video-screen">
          <div className="video">
            <ReactPlayer
              playing={true}
              width={width}
              height={height}
              url={INTROVIDEO}
              controls={false}
              onProgress={handleProgress}
            />
          </div>
        </div>
      ) : showCodeInput ? (
        <HeadShake when={value !== "083" && accepted}>
          <div className="detonatorcode">
            <h2>INSERTAR CÓDIGO DEL DETONADOR: </h2>
            <div className="inputs-code">
              <input disabled inputMode="text" value="P" />
              <div className="input-group">
                <input inputMode="decimal" {...digits[0]} />
                <input inputMode="decimal" {...digits[1]} />
                <input inputMode="decimal" {...digits[2]} />
              </div>
            </div>
            <button
              className="clueButton"
              onClick={() => {
                setShowCodeInput(false);
                window.localStorage.removeItem("setShowCodeInput");
              }}
            >
              Atras
            </button>
            <button
              className="clueButton"
              onClick={() => {
                setAccepted(true);
              }}
            >
              OK
            </button>
          </div>
        </HeadShake>
      ) : null}
    </>
  );
}
