import React from "react";
import { Link } from "react-router-dom";
import GameButton from "../GameButton/GameButton";

export default function GameMenu() {
  return (
    <div className="game-menu">
      <h1>LA BOMBA</h1>
      <Link to={`/la-bomba`}>
        <GameButton
          onClick={() => window.localStorage.setItem("modeChallenge", false)}
          title="MODO NORMAL"
        />
      </Link>
      <Link to={`/la-bomba`}>
        <GameButton
          onClick={() => window.localStorage.setItem("modeChallenge", true)}
          title="MODO COMPETICION"
        />
      </Link>
    </div>
  );
}
