import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import RouteWithSubRoutes from "../../App";
import PensaboxesViewer from "../PensaboxesViewer";
import PhotoScreen from "./PhotoScreen";
import RankingScreen from "./RankingScreen";

export default function GameScreen({ children, routes, data }) {
  let { path, url } = useRouteMatch();

  return (
    <div className="game-screen">
      <Switch>
        <Route exact path={`${path}`}>
          <PensaboxesViewer data={data} />
        </Route>
        <Route path={`${path}/ranking`}>
          <RankingScreen />
        </Route>
        <Route path={`${path}/photo`}>
          <PhotoScreen />
        </Route>
      </Switch>
    </div>
  );
}
