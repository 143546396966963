import { GoogleSpreadsheet } from "google-spreadsheet";
// Config variables
const SPREADSHEET_ID = "1fYhewHic23wMVQ3Ty914C7rVLeGDugKPYTuVfokrjok";
const SHEET_ID = "895952901";
const CLIENT_EMAIL = "pistas@pensabox.iam.gserviceaccount.com";
const PRIVATE_KEY =
  "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCVRvM6FjP5su0a\nQJh+NMrOwBB3DsSwjAQdgT7QSAVUqwjqupbVNHIa4ZPZnQPDVjZu5rvsz4LorNCw\nf5F7OptWL757cTBmu8nQUUKr7JILPpAYplP8nEiqVq1dAH3Z2wYv7igsXutxJn4u\npkCXBC6G2TD5P32N45RI1xjZ+ZVOXeoHq8VhoMCFQ24+E7Gv7tWp+KD9U9tQ9n5u\nFdPvAbG/gvHBFMcve9Gxa0mogDdeTjvY/bOh/9uIlm7V9djryKDe1yXsmREeJeLB\noR28TaRqQEBf4GOlXpO+J1+TA3jwvjwzO3BSSEmRDLdLkJD4l487WxYtq4ro8C0e\nidhets5FAgMBAAECggEABnuESHt6WleY0FEJzkFApWXV0TXE+N27uARK1ASsppae\n1oKv1iZm5h/xqbuV1D/EMWC3vHrudmg6z89aY/2RjVaZcQbjUTLKes9sk8a8ONzI\nqSlf8FJhHXSSJr3EuVc3l5FCEVs14j4HsMLrfyNhWd3IsKo/K5A8ekVNAPpDr5l3\nb7rH1k1ldYjP3wbw+7QhWy3fp5915Ve9N713yBBSbDuvpmRlnE2HypFQnualK1gR\nNMzaYyQ3n9yi8U8z33sq1xs+ZUf0zyt84KQsRzetSnINIJ1PbqiLG+ZAo754Zs8k\nABzFHjDCQAQ9PMQMCPjZBqrhdj3vxJs3VwW062ajKQKBgQDSlp1veHKjPDNcEzpX\nX8Yc2eoBa/lA+nCtlrWVYQ/y8BE1U57jSbAPxSriGI1tfm8cndke1Njsm+meTvUC\nIPxan088uNiBfpHqpCeapXsBBZRkYAtqyu2+zRjiisw2b1pzJiPcE1LVsqdcJz29\nAqSgDcqiMq4PZveR6Klyi5DCQwKBgQC1d7FdjJmJgKDD7LW7ONoCdGBnTcpddX+m\nElJvWZVpfK4De08H/rMaPuglVs5DIRCLojifmJEBoI2ehQLkOSn5Z9JBToaf5KIt\nWroDl/8HOkYSzzgX+qU0P+kzRdKAdNZ9YTd9q/2CMO0/tNlShRI8wlnuZHd0cVTm\nkVoyRfc41wKBgExY7psLQASOPtCS6APlZR3wozYAv00fftpwWUTe+7lH7/U1a9Vb\nUcll4jY2UI/x1ra5Q445/KtLm1cZqRose1EfkMqi+Vt5V82J1hHrDL1CGJqLd5gw\nGiGimfMH/yF9HX3Bxh5zowR20NZII8nyu1yLo3tKhHBxFDCB+WzdzI2hAoGAQfj7\n4HjHMPg0ekZKY4BtscrBaY0cbxHSkHvEwHqfGTScxuNDfjKYxofsOfbvEfSpYTpe\nv5bl0OOf2Ouee35Uxjl6dQepZePnGfoL+lZ9/xCufRQc3orfGLnLfDp8JvtTJ036\nsJOgnH5RIoILKDb8xF4b99FKpGi3ZwOc+P36vQ0CgYEAj9KRdawSl0WFIGr6Re2C\nZlHdelhwMveeW6V/UQDfg2Ju45BWW1wBKkCb//j0b/Cn5JWYdrY4QikT8N8EZcPX\n5wKvGlYf+ukTQ65NoUAvPKU0niN0f14vnTUEk7x/Yp3Nl0PtmlqvYHiEBNTwoq/9\n9sw+yGYhDkCNZ1NsNKtVjG8=\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const appendSpreadsheet = async (row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};
export const saveData = async (boom) => {
  const currentTeam = window.localStorage.getItem("currentTeam");
  const numberClues = window.localStorage.getItem("numberClues");
  const numberSols = window.localStorage.getItem("numberSols");
  const initTimeDate = new Date(
    parseInt(window.localStorage.getItem("initTimeDate"), 10)
  );
  const currentTime = new Date();
  const arrayData = JSON.parse(window.localStorage.getItem("arrayData"));
  const fila = {
    Fecha: initTimeDate.toLocaleDateString(),
    HoraInicio: `${initTimeDate.getHours()}:${initTimeDate.getMinutes()}:${initTimeDate.getSeconds()}`,
    HoraFin: `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`,
    Boom: boom,
    Equipo: currentTeam,
    TotalPistas: numberClues,
    TotalSoluciones: numberSols,
  };
  if (!arrayData) {
    const newArrayData = [];
    newArrayData.push(fila);
    window.localStorage.setItem("arrayData", JSON.stringify(newArrayData));
  } else {
    arrayData.push(fila);
    window.localStorage.setItem("arrayData", JSON.stringify(arrayData));
  }
  // appendSpreadsheet(fila);
};
