import CV from "../../assets/CV.jpg";
import CA from "../../assets/CA.jpg";
import CAM from "../../assets/CAM.jpg";
import CR from "../../assets/CR.jpg";
import CANAZ from "../../assets/CANAZ.jpg";
import CANAM from "../../assets/CANAM.jpg";
import CANVER from "../../assets/CANVER.jpg";
import CB from "../../assets/CB.jpg";
import CMO from "../../assets/CMO.jpg";
import CRX from "../../assets/CRX.jpg";
import CNR1 from "../../assets/CNR1.jpg";
import CNRED from "../../assets/CNRED.jpg";
import CNR2 from "../../assets/CNR2.jpg";
import PL from "../../assets/PL.jpg";
import CNN from "../../assets/CNN.jpg";
import CNG from "../../assets/CNG.jpg";
import PF from "../../assets/PF.jpg";

export default {
  "CV.jpg": CV,
  "CA.jpg": CA,
  "CAM.jpg": CAM,
  "CR.jpg": CR,
  "CANAZ.jpg": CANAZ,
  "CANAM.jpg": CANAM,
  "CANVER.jpg": CANVER,
  "CB.jpg": CB,
  "CMO.jpg": CMO,
  "CRX.jpg": CRX,
  "CNR1.jpg": CNR1,
  "CNRED.jpg": CNRED,
  "CNR2.jpg": CNR2,
  "PL.jpg": PL,
  "CNN.jpg": CNN,
  "CNG.jpg": CNG,
  "PF.jpg": PF,
};
