import {parse,format} from 'date-fns'

export  function mmssToSeconds(mmss) {
  const splitted = mmss.split(":");
  const minutesSeconds = splitted[0] * 60;
  return parseInt(minutesSeconds, 10) + parseInt(splitted[1], 10);
}
export function secondsToMMss(seconds) {
if(seconds===null){
  return;
}  
const parsed = format(parse(seconds, 't', new Date()),'mm:ss');
 return parsed;

}
