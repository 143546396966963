import React, { useState, useEffect } from "react";
import Pulse from "react-reveal/Pulse";
import Challenges from "./Challenges/Challenges";

export default function ChallegersVisor({ section, currentSectionIndex }) {
  return (
    <>
      {section?.text && (
        <div className="box-info">
          {section?.text ? <h2>{section?.text}</h2> : null}

          {section?.number ? <h1>{section?.number}</h1> : null}
          {section?.description ? (
            <Pulse forever={true}>
              <h3>{section?.description}</h3>
            </Pulse>
          ) : null}
          {section?.subDescription ? (
              <h3>{section?.subDescription}</h3>
          ) : null}
        </div>
      )}
      <Challenges
        currentSectionIndex={currentSectionIndex}
        challenges={section}
      />
    </>
  );
}
