import React, { useEffect, useState } from "react";
import { first, last } from "lodash";
import { mmssToSeconds, secondsToMMss } from "./lib";

import ChallengesVisor from "./ChallengesVisor";
import EndGameButton from "./EndGameButton/EndGameButton";
import GameButton from "./GameButton/GameButton";
import GameInit from "./GameInit";
import { Grid } from "semantic-ui-react";
import KoBomb from "./KoBomb";
import Logo from "../assets/log PBX H.png";
import NextButton from "./NextButton/NextButton";
import OkBomb from "./OkBomb";
import PrevButton from "./PrevButton/PrevButton";
import Swal from "sweetalert2";
import TimeBar from "./TimeBar";
import VideoScreen from "./VideoScreen";
import { saveData } from "./saveGoogle";

export default function PensaboxGame({
  data,
  remained,
  timerStart,
  challengeMode,
  endGame,
  endTime,
  okBomb,
  setOkBomb,
  koBomb,
  setKoBomb,
  setEndGame,
  setTimerStart,
  clearTimer,
  teamName,
  newTeamName,
  videoWatched,
  videoWatchedStartGame,
  changeCurrentSection,
  currentSectionIndex,
}) {
  const { sections } = data;
  const isLastSection = currentSectionIndex === last(sections).idSection;
  const isFirstSection = currentSectionIndex === first(sections).idSection;

  const timeNextSeconds = mmssToSeconds(sections[currentSectionIndex].timeNext);
  const remainedParsed = secondsToMMss(timeNextSeconds - remained);

  const showEndButton = remained >= mmssToSeconds("30:00");
  const nextEnabled =
    sections[currentSectionIndex].timeNext && remained >= timeNextSeconds;
  const [showCodeInput, setShowCodeInput] = useState(true);
  const prepared = teamName && videoWatched;
  const showPrevButton = prepared && !isFirstSection;
  // const showNextButton = prepared && !isLastSection;
  const showNextButton = prepared;
  const forcedEnd = window.localStorage.getItem("forcedEnd");
  const showMessage = window.localStorage.getItem("showMessageEnd");
  const [gameStart, setGameStart] = useState(false);
  const hasDesactivatedBomb = () => {
    Swal.fire({
      title: "¿Has desactivado la bomba?",
      showCloseButton: true,
      showDenyButton: true,
      customClass: "big",
      confirmButtonText: `Si, la hemos desactivado`,
      denyButtonText: `No, la bomba ha explotado`,
    }).then((result) => {
      const { isConfirmed, isDismissed, isDenied } = result;
      if (isDismissed) {
        Swal.fire(
          "Recomendamos resolver estos enigmas antes de pasar a las siguientes pistas",
          "",
          "success"
        );
      } else if (isConfirmed) {
        setShowCodeInput(true);

        window.localStorage.setItem("setShowCodeInput", true);
      } else if (isDenied) {
        setKoBomb(true);
        saveData(true);

        window.localStorage.setItem("answered", true);
        window.localStorage.setItem("forcedEnd", true);
      }
    });
  };
  useEffect(() => {
    setShowCodeInput(window.localStorage.getItem("setShowCodeInput"));
  }, []);
  const GameChunk = !teamName ? (
    <GameInit
      newTeamName={newTeamName}
      changeCurrentSection={changeCurrentSection}
    />
  ) : !videoWatched ? (
    <VideoScreen videoWatchedStartGame={videoWatchedStartGame} />
  ) : (timerStart && okBomb) ||
    (timerStart && showCodeInput) ||
    okBomb ||
    (endTime && showCodeInput) ? (
    <OkBomb showCodeInput={showCodeInput} setShowCodeInput={setShowCodeInput} />
  ) : koBomb || forcedEnd || endTime ? (
    <KoBomb
      showModal={endTime && !showMessage ? true : false}
      setShowCodeInput={setShowCodeInput}
      koBomb={koBomb || forcedEnd}
      setKoBomb={setKoBomb}
      okBomb={okBomb}
      setOkBomb={setOkBomb}
    />
  ) : timerStart ? (
    <>
      <Grid.Row className="timer-visor">
        <Grid.Column width={16}>
          <TimeBar
            data={data}
            timer={remained}
            currentSectionIndex={currentSectionIndex}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="clue-visor">
        <Grid.Column width={3} className="prevButton-column btnColumn">
          {showPrevButton ? (
            <PrevButton
              changeCurrentSection={changeCurrentSection}
              currentSectionIndex={currentSectionIndex}
              disabled={currentSectionIndex === 0}
            />
          ) : null}
        </Grid.Column>
        <Grid.Column tablet={10} computer={10} mobile={16}>
          <ChallengesVisor
            currentSectionIndex={currentSectionIndex}
            section={sections[currentSectionIndex]}
          />
        </Grid.Column>
        <Grid.Column width={3} className="nextButton-column btnColumn">
          {showNextButton && !isLastSection ? (
            <div>
              <NextButton
                disabled={!nextEnabled}
                onClick={() => {
                  changeCurrentSection(currentSectionIndex + 1);
                }}
              />
              {!nextEnabled && <h1>{remainedParsed} min</h1>}
            </div>
          ) : null}
          {showEndButton && (
            <EndGameButton
              onClick={() => {
                hasDesactivatedBomb();
              }}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </>
  ) : null;
  return (
    <Grid className="pensabox-game">
      {!prepared && (
        <Grid.Row>
          <div className="topBar">
            <img src={Logo} className="logo" />
            <span>LA BOMBA</span>
          </div>
        </Grid.Row>
      )}

      {!gameStart ? (
        <div className="game-menu">
          <h1>LA BOMBA</h1>
          <GameButton onClick={() => setGameStart(true)} title="Iniciar" />
        </div>
      ) : (
        GameChunk
      )}
    </Grid>
  );
}
