import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { SECONDS_GAME } from "./constants";
import { mmssToSeconds } from "./lib";
import { format, addSeconds } from "date-fns";
import Jump from "react-reveal/Jump";
export default function TimeBar({
  timer,
  remained,
  data,
  currentSectionIndex,
}) {
  const remainedFormatted = remained
    ? format(addSeconds(new Date(0), remained), "mm:ss")
    : null;
  return (
    <div className="timer-bar ">
      <div className="outer-bar">
        <div
          className="inner-bar"
          style={{ width: `${(100 * timer) / SECONDS_GAME}%` }}
        >
          {remainedFormatted}
        </div>
      </div>
      <div className="checkPoints">
        {data.sections.map((section, i) => {
          return (
            <span
              key={i}
              className="separator"
              style={{
                left: `${parseInt(i, 10) * 10}%`,
              }}
            >
              {/* {section.idSection} */}
            </span>
          );
        })}
        <Jump>
          <span
            className="currentCheck"
            style={{
              left: `${parseInt(currentSectionIndex, 10) * 10}%`,
            }}
          >
            {/* {section.idSection} */}
          </span>
        </Jump>
      </div>
    </div>
  );
}
