import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import INTROVIDEO from "../assets/INTROVIDEO.mp4";
import { useWindowSize } from "react-use";
import Fade from "react-reveal/Fade";

export default function VideoScreen({ videoWatchedStartGame }) {
  const [challengeMode, setChallengeMode] = useState(false);

  //TODO FIX BOOL
  const [videoWatched, setVideoWatched] = useState(false);
  const playerRef = useRef();
  const { width, height } = useWindowSize();

  const handleProgress = (state) => {
    const { playedSeconds, loadedSeconds } = state;
    if (playedSeconds >= loadedSeconds) {
      setVideoWatched(true);
    }
  };
  useEffect(() => {
    if (window.localStorage.getItem("modeChallenge")) {
      setChallengeMode(
        window.localStorage.getItem("modeChallenge") === "true" ? true : false
      );
    }
  }, []);
  return (
    <div className="video-screen">
      {videoWatched ? (
        <div className="video-screen_info-box">
          {challengeMode ? (
            <h1>
              DALE AL SIGUIENTE BOTÓN CUANDO AMBOS EQUIPOS ESTÉN PREPARADOS PARA
              JUGAR Y PARA ABRIR LA CAJA. PRESIONAD LOS DOS EQUIPOS A LA VEZ EL
              BOTÓN PARA EMPEZAR AL MISMO TIEMPO.
            </h1>
          ) : (
            <h1>
              DALE AL SIGUIENTE BOTÓN CUANDO TODO EL EQUIPO ESTÉ PREPARADO PARA
              JUGAR Y PARA ABRIR LA CAJA
            </h1>
          )}
          <button
            className="norm-button"
            // disabled={!videoWatched}
            onClick={() => {
              videoWatchedStartGame();
            }}
          >
            Abrir la caja
          </button>
        </div>
      ) : (
        <Fade>
          <div className="video">
            <ReactPlayer
              ref={playerRef}
              playing={true}
              width={width}
              height={videoWatched ? height / 2 : height / 1.4}
              url={INTROVIDEO}
              controls={false}
              onProgress={handleProgress}
            />
          </div>
        </Fade>
      )}
    </div>
  );
}
