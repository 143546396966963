import React from "react";
import nextButton from "../../images/next_button.png";
import nextButtonDisabled from "../../images/next_button_disabled.png";
import "./NextButton.scss";

export default function NextButton({
  disabled,
  onClick,
  changeCurrentSection,
  currentSectionIndex,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="nextButton"
    >
      <img src={disabled ? nextButtonDisabled : nextButton} alt=">" />
    </button>
  );
}
