import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import PensaboxGame from "./PensaboxGame";
import Timer from "./Timer";
import TimeBar from "./TimeBar";
import Game from "./Game";
import Controls from "./Controls";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RouteWithSubRoutes from "../App";
import { AiOutlineReload } from "react-icons/ai";

export default function PensaboxesViewer({ data, routes }) {
  let match = useRouteMatch();
  const [lastSection, setLastSection] = useState(
    parseInt(window.localStorage.getItem("lastSection")) || 0
  );
  const [currentSectionIndex, setCurrentSectionIndex] = useState(lastSection);

  const Modal = withReactContent(Swal);
  if (!window.localStorage) {
    return null;
  }
  return (
    <Container
      fluid
      className="pensaboxes-viewer"
      // style={{
      //   backgroundImage: `url(${fondo})`,
      // }}
    >
      <button
        className="admin-btn"
        onClick={() => {
          Swal.mixin({
            input: "number",
            confirmButtonText: "Entrar",
          })
            .queue([
              {
                title: "Contraseña",
              },
            ])
            .then((result) => {
              if (result.value) {
                const password = result.value[0];
                if (password === "1234") {
                  Modal.fire({
                    html: <Controls />,
                  });
                }
              }
            });
        }}
      >
        X
      </button>
      <button
        className="reload-btn"
        onClick={() => {
          window.location.href = window.location.origin;
        }}
      >
        <AiOutlineReload />
      </button>
      <Timer>
        <Game
          data={data}
          lastSection={lastSection}
          setLastSection={setLastSection}
          currentSectionIndex={currentSectionIndex}
          setCurrentSectionIndex={setCurrentSectionIndex}
        >
          <PensaboxGame />
        </Game>
      </Timer>
    </Container>
  );
}
