import React, { useState, useEffect } from "react";
import { SECONDS_GAME } from "./constants.js";
export default function Timer({ children }) {
  const [timerStart, setTimerStart] = useState(false);
  const [initTimeDate, setInitTimeDate] = useState(false);
  const [endGame, setEndGame] = useState(false);
  const [okBomb, setOkBomb] = useState(null);
  const [koBomb, setKoBomb] = useState(null);
  const [endTime, setEndTime] = useState(false);
  const [counter, setCounter] = useState(null);
  useEffect(() => {
    setTimerStart(window.localStorage.getItem("initTimeDate") ? true : false);

    setInitTimeDate(window.localStorage.getItem("initTimeDate") || false);
    const initTimeDateSaved = Number(
      window.localStorage.getItem("initTimeDate")
    );
    const forcedEnd = window.localStorage.getItem("forcedEnd");

    if (forcedEnd) {
      setKoBomb(true);
    }
    const initialCounter = initTimeDateSaved
      ? new Date().getTime() - initTimeDateSaved
      : null;
    setCounter(parseInt(initialCounter / 1000, 10));
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem("initTimeDate") && timerStart) {
      const timeStamp = new Date().getTime();
      window.localStorage.setItem("initTimeDate", timeStamp);
      setInitTimeDate(timeStamp);
    }
  }, [timerStart]);

  useEffect(() => {
    if (counter >= SECONDS_GAME) {
      setEndTime(true);
      // setEndGame(true);
      clearTimer();
    }
  }, [counter]);

  useEffect(() => {
    if (timerStart) {
      const timer =
        counter !== null &&
        setInterval(() => {
          const timeStampNow = new Date().getTime() - initTimeDate;
          setCounter(parseInt(timeStampNow / 1000, 10));
        }, 1000);
      return () => clearInterval(timer);
    }
  }, [counter, initTimeDate, timerStart]);

  const clearTimer = () => {
    setTimerStart(false);
    setCounter(false);
    setInitTimeDate(false);
    // window.localStorage.removeItem("initTimeDate");
  };
  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      timer: counter,
      remained: counter,
      clearTimer,
      timerStart,
      setTimerStart,
      endTime,
      endGame,
      setEndGame,
      okBomb,
      setOkBomb,
      koBomb,
      setKoBomb,
    })
  );
}
