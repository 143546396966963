import React from "react";
import imgButton from "../../images/prev_button.png";
import "./PrevButton.scss";

export default function PrevButton({
  changeCurrentSection,
  currentSectionIndex,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        changeCurrentSection(currentSectionIndex - 1);
      }}
      className="prevButton"
    >
      <img src={imgButton} alt="<" />
    </button>
  );
}
