import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import PensaboxesViewer from "./components/PensaboxesViewer";
import getRoutes from "./routes/routes";
import "../src/scss/App.scss";
import Fondo from "../src/assets/FONDAZOKK.jpg";
import "semantic-ui-css/semantic.min.css";

export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} data={route.data} />
      )}
    />
  );
}
export default function App() {
  const routes = getRoutes();
  if (!window.localStorage) {
    return null;
  }
  return (
    <div className="root" style={{ backgroundImage: `url(${Fondo})` }}>
      <BrowserRouter>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </BrowserRouter>
    </div>
  );
}
