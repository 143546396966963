import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export default function AdminMenu() {
  let match = useRouteMatch();
  function clearData() {
    localStorage.clear();
  }
  return (
    <div className="admin-menu">
      <Link to={`/la-bomba`}>La BOMBA</Link>
      <button onClick={() => clearData()}>Borrar datos</button>
    </div>
  );
}
