import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import Swal from "sweetalert2";

export default function Game({
  children,
  data,
  setTimerStart,
  endGame,
  setEndGame,
  lastSection,
  setLastSection,
  currentSectionIndex,
  setCurrentSectionIndex,
  okBomb,
  setOkBomb,
  koBomb,
  setKoBomb,
  ...rest
}) {
  const [teamName, setTeamName] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [challengeMode, setChallengeMode] = useState(false);
  const Modal = Swal;
  const lastSectionWatched = parseInt(
    window.localStorage.getItem("lastSection") || 0
  );
  function hasChallengesResolved(callback) {
    Swal.fire({
      title: "Tienes ya estos enigmas resueltos",

      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `No`,
    }).then((result) => {
      const { isConfirmed, isDismissed } = result;
      /* Read more about isConfirmed, isDenied below */
      if (isDismissed) {
        Swal.fire(
          "Recomendamos resolver estos enigmas antes de pasar a las siguientes pistas",
          "",
          "success"
        );
      } else if (isConfirmed) {
        callback();
      }
    });
  }

  function changeSection(newSection) {
    if (newSection > 0 && newSection < data.sections.length) {
      if (newSection > currentSectionIndex) {
        if (newSection === 1) {
          window.localStorage.setItem("lastSection", newSection);
          setCurrentSectionIndex(newSection);
          setCurrentSectionData(data.sections[newSection]);
          window.localStorage.setItem("lastSection", newSection);
        } else if (newSection > lastSectionWatched) {
          hasChallengesResolved(() => {
            window.localStorage.setItem("lastSection", newSection);
            setCurrentSectionIndex(newSection);
            setCurrentSectionData(data.sections[newSection]);
            window.localStorage.setItem("lastSection", newSection);
          });
        } else {
          setCurrentSectionIndex(newSection);
        }
      } else {
        setCurrentSectionIndex(newSection);
      }
    }
  }
  const [currentSectionData, setCurrentSectionData] = useState(
    data.sections[lastSection]
  );
  useEffect(() => {
    if (endGame) {
      // window.localStorage.removeItem("lastSection");
      // window.localStorage.removeItem("currentTeam");
      // window.localStorage.removeItem("endGame");
      // window.localStorage.removeItem("videoWatched");
      // window.localStorage.removeItem("watchedClues");
      // window.localStorage.removeItem("numberSols");
      // window.localStorage.removeItem("numberClues");
      // setLastSection(0);
      // setTeamName(false);
      // setEndGame(true);
      // setVideoWatched(false);
    }
  }, [endGame, setEndGame, setLastSection]);

  useEffect(() => {
    setTeamName(window.localStorage.getItem("currentTeam") || false);
    setVideoWatched(window.localStorage.getItem("videoWatched") || false);
    setChallengeMode(window.localStorage.getItem("challengeMode") || false);
    setLastSection(parseInt(window.localStorage.getItem("lastSection")) || 0);
  }, []);
  const newTeamName = (name) => {
    setTeamName(name);
    window.localStorage.setItem("currentTeam", name);
  };
  const videoWatchedStartGame = () => {
    // clearTimer();
    setVideoWatched(true);
    window.localStorage.setItem("videoWatched", true);
    setTimerStart(true);
  };

  const newProps = {
    teamName: teamName,
    videoWatched: videoWatched,
    challengeMode: challengeMode,
    lastSection: lastSection,
    currentSectionIndex: currentSectionIndex,
    currentSectionData: currentSectionData,
    newTeamName: newTeamName,
    videoWatchedStartGame: videoWatchedStartGame,
    changeCurrentSection: changeSection,
    endGame,
    setEndGame,
    setTimerStart: setTimerStart,
    okBomb,
    setOkBomb,
    koBomb,
    setKoBomb,
    data: data,
    ...rest,
  };
  return React.Children.map(children, (child) =>
    React.cloneElement(child, newProps)
  );
}
