import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
export default function PhotoScreen() {
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    // console.log("takePhoto");
  }
  return (
    <div className="ranking-screen">
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
      />
    </div>
  );
}
