import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

export default function RankingScreen() {
  return <div className="ranking-screen">RankingScreen</div>;
}
