import React from "react";
import classnames from 'classnames';
import clueButtonImg from "../../images/clue_button.png";
import clueButtonImgPressed from "../../images/clue_button_pressed.png";
import "./ClueButton.scss";

export default function ClueButton({ title, text, image, pressed, onClick }) {
  return (
    <button className={classnames({"clueButton":true,pressed:pressed})} onClick={onClick}>
      <span>{title}</span>
    </button>
  );
}
