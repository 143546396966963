import React from "react";
import { Grid } from "semantic-ui-react";

import Challenge from "../Challenge/Challenge";
import "./Challenges.scss";

export default function Challenges({ challenges,currentSectionIndex }) {
  if (!challenges?.challenges) {
    return null;
  }
  return (
    <Grid className="challenges">
      <Grid.Row columns={challenges.challenges.length}>
        {challenges.challenges.map((challenge, i) => (
          <Grid.Column key={i}>
            <Challenge currentSectionIndex={currentSectionIndex} challenge={challenge} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
}
