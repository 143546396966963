import React from "react";
import buttonImg from "../../images/BOTON_JT.png";
import "./EndGameButton.scss";

export default function EndGameButton({ onClick }) {
  return (
    <button className="deactivatedBtn" onClick={onClick}>
      <img src={buttonImg} alt="<" />
    </button>
  );
}
