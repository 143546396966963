// Layouts
import AdminScreen from "../components/screens/adminScreen";
import AdminMenu from "../components/menus/AdminMenu";
import PensaboxesViewer from "../components/PensaboxesViewer";
import RankingScreen from "../components/screens/RankingScreen";
import cluesJson from "../data/pistas.json";
import PhotoScreen from "../components/screens/PhotoScreen";
import GameScreen from "../components/screens/GameScreen";
import GameMenu from "../components/menus/GameMenu";

// Pages

const noop = () => null;
export default function getRoutes() {
  return [
    {
      path: "/",
      exact: true,
      component: GameMenu,
      data: cluesJson,
    },
    {
      path: "/la-bomba",
      component: GameScreen,
      data: cluesJson["la-bomba"],
      routes: [
        {
          path: "/la-bomba",
          component: PensaboxesViewer,
        },
        {
          path: "/ranking",

          component: RankingScreen,
        },
        {
          path: "/photo",
          exact: true,

          component: PhotoScreen,
          data: cluesJson,
        },
      ],
    },

    {
      path: "/admin",
      exact: true,
      component: AdminMenu,
      data: cluesJson,
    },
  ];
}
